<template>
  <ion-page>
    <HeaderMain page-title="Map" default-href="/home" />
    <ion-content :fullscreen="true">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2234.19234972423!2d-3.209594!3d55.946037999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTXCsDU2JzQ1LjciTiAzwrAxMiczNC41Ilc!5e0!3m2!1sen!2sng!4v1635703809527!5m2!1sen!2sng"
        width="100%"
        height="100%"
        frameborder=""
        style="border: 0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
      <!-- <div class="map" ref="mapDivRef"></div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import HeaderMain from '@/components/shared/HeaderMain.vue';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'Map',
  components: {
    IonContent,
    IonPage,
    HeaderMain,
  },
  setup() {
    const map = ref(null);
    const mapDivRef = ref(null);

    onMounted(() => {
      // load in the google script
      // key is is the .env file
      // const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
      // // create the script element to load
      // const googleMapScript = document.createElement('SCRIPT');
      // googleMapScript.setAttribute(
      //   'src',
      //   `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`
      // );
      // googleMapScript.setAttribute('defer', '');
      // googleMapScript.setAttribute('async', '');
      // document.head.appendChild(googleMapScript);
      // console.log('Home page did enter');
    });
    /**
     * this function is called as soon as the map is initialized
     */
    const venueLocation = {
      lat: 55.946038,
      lng: -3.209594,
    };

    window.initMap = () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        mapTypeId: 'hybrid',
        zoom: 16,
        disableDefaultUI: false,
        center: venueLocation,
      });
      new window.google.maps.Marker({
        position: venueLocation,
        map: map.value,
        title: 'EEIC - EDINBURGH INTERNATIONAL CONFERENCE CENTRE',
      });
    };

    return {
      mapDivRef,
    };
  },
});
</script>

<style scoped>
.map {
  height: 100%;
  width: 100%;
  background-color: transparent;
  transition: opacity 150ms ease-in;
}
</style>
